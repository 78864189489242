<!--========================================================
														CONTENT 
	=========================================================-->
<section id="content">
  <div class="container">
    <div class="box-2 v2  wow fadeInUp">
      <h2 class="txt_cntr marTop7 color1">Browse Our Most Popular Categories</h2>

      <div class="col3">
        <div class="wrapper marTop10">
          <img src="assets/images/page5_icon5.png" alt="" class="img7 no_resize">
          <div class="box">
            <p class="txt6"><a href="#Paranormal" class="link3" appAnchorScroll>Paranormal Romance</a><br>Bringing you
              the Alphas of
              your dreams. </p>
          </div>
        </div>

        <div class="wrapper marTop10">
          <img src="assets/images/page5_icon2.png" alt="" class="img7 no_resize">
          <div class="box">
            <p class="txt6"><a href="#Fantasy" class="link3" appAnchorScroll> Fantasy Romance</a><br>Alpha's from other
              worlds
            </p>
          </div>
        </div>


      </div>


      <div class="col3">
        <div class="wrapper marTop10">
          <img src="assets/images/page5_icon6.png" alt="" class="img7 no_resize">
          <div class="box">
            <p class="txt6"><a href="#SciFi" class="link3" appAnchorScroll>Sci-Fi Romance</a><br>Sci Fi with sweet love!
            </p>
          </div>
        </div>
        <div class="wrapper marTop10">
          <img src="assets/images/page5_icon3.png" alt="" class="img7 no_resize">
          <div class="box">
            <p class="txt6"><i class="fa fa-heartbeat"></i><a href="#Contemporary" class="link3" appAnchorScroll>
                Contemporary
                Romance</a><br>Women who know what they want</p>
          </div>
        </div>
      </div>


      <div class="col3 last">
        <div class="wrapper marTop10">
          <img src="assets/images/page5_icon8.png" alt="" class="img7 no_resize">
          <div class="box">
            <p class="txt6"><a href="#Erotic" class="link3" appAnchorScroll>Erotic Romance</a><br>Pushing the boundaries
              of fiction.
            </p>
          </div>
        </div>
        <div class="wrapper marTop10">
          <img src="assets/images/page5_icon4.png" alt="" class="img7 no_resize">
          <div class="box">
            <p class="txt6"><a href="#Suspense" class="link3" appAnchorScroll>Romance Suspense</a><br>Pulse pounding
              Delights
            </p>
          </div>
        </div>
      </div>


    </div>

    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2">Paranormal Romance<a id="Paranormal"></a></h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">

        <div class="wrapper">
          <img src="assets/images/WolfClaimed2020-Final-Small.png" alt="WolfClaimed" class="img5" width="270"
            height="380">
          <h3 class="lh24"><a href="https://www.createspace.com/5722587" class="link">Wolf Claimed</a><a
              id="wolfclaimed"></a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="http://www.barnesandnoble.com/w/wolf-claimed-mercedes-bleau/1119977070?ean=2940149737857"
              class="link2" target="_blank">E-Book; </a><a href="http://a.co/jhI0E2f" class="link2"
              target="_blank">Kindle</a></p>
        </div>



      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.2s">



        <div class="wrapper">
          <img src="assets/images/WitchBetrayed2020_Final_Small.png" alt="WitchBetrayed" class="img5" width="270"
            height="380">
          <h3 class="lh24"><a href="https://www.amazon.com/Witch-Betrayed-Episode-Books-MagKaen-ebook/dp/B01LZU9T98"
              class="link">Witch Betrayed</a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="http://www.barnesandnoble.com/w/witch-betrayed-1-mercedes-bleau/1124649984?ean=2940153736778"
              class="link2" target="_blank">E-Book; </a><a
              href="https://www.amazon.com/Witch-Betrayed-Episode-Books-MagKaen-ebook/dp/B01LZU9T98" class="link2"
              target="_blank">Kindle</a></p>
        </div>

      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic3.jpg" alt="" class="img5"> -->
          <!--<h3 class="lh24"><a href="#" class="link">Proin Dictum <br>Elementum Velit</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic4.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Congue<br>Fermentum Nisl</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic5.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Vestibulum Iaculis<br>Lacinia Est</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic6.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">In Faucibus Orci<br>Luctus Et</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic7.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Pellentesque Sed<br>Dolor Aliquam</a></h3>
						<p>by <a href="#" class="link2">Tom Brown</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic8.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Aenean Nonummy<br>Hendrerit</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2">Fantasy Romance<a id="Fantasy"></a></h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">
        <div class="wrapper">Check back later...
          <!--<img src="../assets/images/page5_pic9.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Lorem Ipsum<br>Dolor Sit Amet</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic10.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Mauris Accumsan<br>Nulla Ve</a></h3>
						<p>by <a href="#" class="link2">Tom Brown</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic11.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Proin Dictum <br>Elementum Velit</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic12.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Congue<br>Fermentum Nisl</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic13.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Vestibulum Iaculis<br>Lacinia Est</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic14.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">In Faucibus Orci<br>Luctus Et</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic15.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Pellentesque Sed<br>Dolor Aliquam</a></h3>
						<p>by <a href="#" class="link2">Tom Brown</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic16.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Aenean Nonummy<br>Hendrerit</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>
    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2">Sci-Fi Romance<a id="SciFi"> </a></h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">

        <div class="wrapper">
          <img src="assets/images/MidNightCover@0,25x.png" alt="Midnight" class="img5" width="270" height="380">
          <h3 class="lh24"><a href="#" class="link">Midnight</a></h3>
          <p>by <a href="mrbooks.html" class="link2">Melanie Raider</a><br><br>Additional formats: <br>
            <a href="#" class="link2" target="_blank">E-Book; </a><a href="#" class="link2" target="_blank">Kindle

            </a></p>
        </div>
        <div class="wrapper">
          <!--<img src="../assets/images/WolfClaimed2015SW_BIGG.jpg" alt="WitchBetrayed" class="img5" width="270" height="380">
						<h3 class="lh24"><a href="#" class="link">Witch Betrayed</a></h3>
						<p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br> 
                        <a href="" class="link2">E-Book; </a><a href="" class="link2">Kindle</a></p> -->
        </div>

      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic3.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Proin Dictum <br>Elementum Velit</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic4.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Congue<br>Fermentum Nisl</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic5.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Vestibulum Iaculis<br>Lacinia Est</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic6.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">In Faucibus Orci<br>Luctus Et</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic7.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Pellentesque Sed<br>Dolor Aliquam</a></h3>
						<p>by <a href="#" class="link2">Tom Brown</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic8.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Aenean Nonummy<br>Hendrerit</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2">Contemporary Romance<a id="Contemporary"></a></h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">

        <div class="wrapper">Check back later....
          <!--<img src="../assets/images/WolfClaimed2015SW_BIGG.jpg" alt="WolfClaimed" class="img5" width="270" height="380">
						<h3 class="lh24"><a href="#" class="link">Wolf Claimed</a></h3>
						<p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br> 
                        <a href="#" class="link2">E-Book; </a><a href="#" class="link2">Kindle</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/WolfClaimed2015SW_BIGG.jpg" alt="WitchBetrayed" class="img5" width="270" height="380">
						<h3 class="lh24"><a href="#" class="link">Witch Betrayed</a></h3>
						<p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br> 
                        <a href="" class="link2">E-Book; </a><a href="" class="link2">Kindle</a></p> -->
        </div>

      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic3.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Proin Dictum <br>Elementum Velit</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic4.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Congue<br>Fermentum Nisl</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic5.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Vestibulum Iaculis<br>Lacinia Est</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic6.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">In Faucibus Orci<br>Luctus Et</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic7.jpg" alt="" class="img5">
						<h3 class="lh24"><a href="#" class="link">Pellentesque Sed<br>Dolor Aliquam</a></h3>
						<p>by <a href="#" class="link2">Tom Brown</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic8.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Aenean Nonummy<br>Hendrerit</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2">Erotic <a id="Erotic"></a> </h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.6s">

        <div class="wrapper">
          <img src="assets/images/Farbo5@0,25x.jpg" alt="Farbo" class="img5" width="270" height="380">
          <h3 class="lh24"><a href="https://www.createspace.com/5852163" class="link">Farbo</a><a id="farbo"></a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="http://www.barnesandnoble.com/w/farbo-mercedes-bleau/1122259899?ean=2940152014235" class="link2"
              target="_blank">E-Book; </a><a href="http://www.amazon.com/dp/B01680T0P6" class="link2"
              target="_blank">Kindle</a></p>
        </div>



      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.8s">



        <div class="wrapper">
          <img src="assets/images/Lusten4@0,25x.jpg" alt="Lusten" class="img5" width="270" height="380">
          <h3 class="lh24"><a href="#" class="link">Lusten</a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="https://www.barnesandnoble.com/w/satiation-mercedes-bleau/1128789854?ean=2940162127703"
              class="link2" target="_blank">E-Book; </a><a
              href="https://www.amazon.com/Lusten-Kingdom-Nareth-Book-2-ebook/dp/B072SNXBCK/ref=sr_1_4?ie=UTF8&qid=1528603370&sr=8-4&keywords=mercedes+bleau"
              class="link2" target="_blank">Kindle</a></p>
        </div>

      </div>
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">
        <div class="wrapper">
          <img src="assets/images/San3@0,25x.jpg" alt="San" class="img5" width="270" height="380">
          <h3 class="lh24"><a href="#" class="link">San</a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="https://www.barnesandnoble.com/w/satiation-mercedes-bleau/1128789854?ean=2940162127703"
              class="link2" target="_blank">E-Book; </a><a
              href="https://www.amazon.com/San-Kingdom-Nareth-Book-3-ebook/dp/B07B42TW6V/ref=sr_1_2?ie=UTF8&qid=1528603370&sr=8-2&keywords=mercedes+bleau"
              class="link2" target="_blank">Kindle</a></p>
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic4.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Congue<br>Fermentum Nisl</a></h3>
						<p>by <a href="#" class="link2">Steven Davis</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">
        <div class="wrapper">
          <img src="assets/images/BloodMother-Draft3-SMALL-350.jpg" alt="bloodmother" class="img5" width="270"
            height="380">
          <h3 class="lh24"><a href="#" class="link">Blood Mother</a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="https://www.barnesandnoble.com/s/mercedes+bleau?_requestid=4774371" class="link2"
              target="_blank">E-Book; </a><a
              href="https://www.amazon.com/Blood-Mother-Kingdom-Nareth-Book-ebook/dp/B07B7JGCHB/ref=sr_1_5?ie=UTF8&qid=1528603370&sr=8-5&keywords=mercedes+bleau"
              class="link2" target="_blank">Kindle</a></p>
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic6.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">In Faucibus Orci<br>Luctus Et</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.6s">
        <div class="wrapper">
          <img src="assets/images/SatiationTrilogy@0,25x.jpg" alt="satiation" class="img5" width="270" height="380">
          <h3 class="lh24"><a href="#" class="link">Satiation Trilogy</a></h3>
          <p>by <a href="#" class="link2">Mercedes Bleau</a><br><br>Additional formats: <br>
            <a href="https://www.barnesandnoble.com/w/satiation-mercedes-bleau/1128789854?ean=2940162127703"
              class="link2" target="_blank">E-Book; </a><a href="http://a.co/fiEiQjo" class="link2"
              target="_blank">Kindle</a></p>
        </div>

        <div class="wrapper">
          <!--<img src="../assets/images/page5_pic8.jpg" alt="" class="img5">
				    <h3 class="lh24"><a href="#" class="link">Aenean Nonummy<br>Hendrerit</a></h3>
						<p>by <a href="#" class="link2">Jayden Tompson</a><br><br>Additional formats: <br><a href="#" class="link2">Hardcover;</a> <a href="#" class="link2">E-Book</a></p> -->
        </div>
      </div>

    </div>
    <div class="hline v7"></div>
    <h2 class="txt_cntr marTop2">Romantic Suspense<a id="Suspense"></a></h2>
    <div class="row txt_cntr">
      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.4s">

        <div class="wrapper">
          <img src="assets/images/Evince-Large.jpg" alt="Evince" class="img5" width="270" height="380">
          <h3 class="lh24"><a href="#" class="link">Evince</a></h3>
          <p>by <a href="#" class="link2">A. Mallory Hughes</a><br><br>Additional formats: <br>
            <a href="#" class="link2">E-Book; </a><a href="#" class="link2">Kindle</a></p>
        </div>


      </div>

      <div class="grid_3 wow fadeInLeft" data-wow-duration="" data-wow-delay="0.0s">

      </div>

      <div class="grid_3 wow fadeInRight" data-wow-duration="" data-wow-delay="0.2s">

      </div>


    </div>
  </div>


</section>